<template>
  <div class="container">
    <bread-crumb :navigationArray="navigationLink" />
    <div class="white-container">
      <h2 class="title_categories">Личный кабинет</h2>
      <tricolor-line class="card_containet__tricolor" />
      <div class="content-sb">
        <h3 class="sub-title_categories">Блог</h3>
        <router-link :to="`/create_blog?idUser=${$route.query.idUser}`" class="btn-blue">Создать</router-link>
      </div>

      <div class="wrapper-blog">
        <Blog from="admin" link="/detail_blog?status=1" />
      </div>
    </div>
  </div>

</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import Blog from '@/components/memberProfile/Blog'
export default {
  name: 'blog',
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Блог'
        }
      ]
    }
  },
  components: {
    BreadCrumb,
    TricolorLine,
    Blog
  },
  mounted () {
    this.$store.dispatch('apiMemberPost', {
      id: this.$route.query.idUser,
      isPublic: false
    })
  }
}
</script>

<style scoped lang="scss">
.white-container{
  width: 61.68rem;
  margin: 0 0 0 -2.5rem;
}
.content-sb{
  align-items: center;
}
.wrapper-blog{
  margin-top: 1.25rem;
}
</style>
